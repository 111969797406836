'use client'
import "./HeaderNavBar.css";
import { RiMenu3Fill } from "react-icons/ri";
import React, { useState, useEffect } from 'react';
import LogoBRP from "../../../public/Batista & Rodrigues-LOGO.webp"
import HeaderMenuBar from "@/components/headerMenuBar/HeaderMenuBar";
import {IoClose} from "react-icons/io5";
import Image from "next/image";

const HeaderNavBar = () => {
    const [isSticky, setIsSticky] = useState(false);
    const [activeMenu , setActiveMenu] =useState(false);

    const handleScroll = () => {
        const shouldStick = window.scrollY > 0;
        setIsSticky(shouldStick);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleActiveMenu = () => {
        setActiveMenu(!activeMenu);
    };

    return (
        <div className={`${isSticky ? "nav-bar-main sticky" : "nav-bar-main"} ${activeMenu ? "nav-bar-main active" : "nav-bar-main" }`}>
            <div className="nav-bar-main-container">
                <div className="nav-bar-main-container-left">
                    <ul className="nav-bar-list">
                        <li><a href="#sobre-nos">Sobre-nos</a></li>
                        <li><a href="#como-trabalhamos">Como Trabalhamos</a></li>
                    </ul>
                </div>
                <div className="nav-bar-main-container-middle">
                    <div className="nav-bar-logo">
                        <Image
                            src={LogoBRP}
                            alt="Logo Batista & Rodrigues Advogados Associados"
                            width={500}
                            height={93}
                        />
                    </div>
                </div>
                <div className="nav-bar-main-container-right">
                    <ul className="nav-bar-list">
                        <li><a href="#contact">Contato</a></li>
                        <li><a href="#">Area Restrita</a></li>
                    </ul>
                    <div className={"nav-bar-menu"} onClick={handleActiveMenu}>
                        <RiMenu3Fill />
                    </div>
                </div>
            </div>
            {activeMenu && (
                <div className={"header-nav-bar-menu"}>
                <IoClose onClick={() => setActiveMenu(false)}/>
                <HeaderMenuBar activeMenu={activeMenu} setActiveMenu={setActiveMenu}/>
                </div>
            )}
        </div>
    );
}

export default HeaderNavBar;

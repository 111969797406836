'use client'
import {useState, useEffect} from 'react';
import './ContactForm.css'
import {Modal, Box, Button, TextField, Typography} from '@mui/material';
import {MdEmail, MdError} from "react-icons/md";

interface FormData {
    name: string;
    email: string;
    message: string;
}

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

const ContactForm: React.FC = () => {
    // Use the FormData interface for the state
    const [formData, setFormData] = useState<FormData>({name: '', email: '', message: ''});
    const [showModal, setShowModal] = useState(false);

    return (
        <div className="contact-form-main" id="contact">
            <div className="contact-form-header">
                <p> Vamos nos conectar</p>
            </div>
            <div className="contact-form-introduction">
                <div className="contact-form-title">
                    <h2> Entre em contato </h2>
                    <MdEmail className="form-email-icon"/>
                </div>
                <div className="contact-form-text"><p>
                    Explore o card com nossas perguntas e respostas e descubra como podemos ajudar a resolver suas
                    dúvidas jurídicas. Cada questão foi cuidadosamente respondida por nossos advogados experientes,
                    abordando tópicos essenciais de forma clara e acessível. Aproveite esse recurso para esclarecer suas
                    dúvidas e, se precisar de orientação adicional, entre em contato conosco. Estamos prontos para
                    oferecer o suporte jurídico que você precisa!
                </p></div>
            </div>
            <div className="contact-form-full">
                <form className="contact-form">
                    <div className="contact-form-inputs">
                        <TextField
                            label="Nome"
                            variant="outlined"
                            name="name"
                            fullWidth
                            margin="normal"
                            value={formData.name}
                            required
                        />
                        <TextField
                            label="E-mail"
                            variant="outlined"
                            name="email"
                            type="email"
                            fullWidth
                            margin="normal"
                            value={formData.email}
                            required
                        />
                        <TextField
                            label="Mensagem"
                            variant="outlined"
                            name="message"
                            multiline
                            rows={4}
                            fullWidth
                            margin="normal"
                            value={formData.message}
                            required
                        />
                        <Button type="submit" variant="contained" color="primary">ENVIAR</Button>
                    </div>
                </form>
            </div>
        </div>
    );
}


export default ContactForm;
import "./HeaderContent.css"
import {FaStar} from "react-icons/fa";

function HeaderContent() {
    return (
        <nav className="header-content-main">
            <div className="header-content-main-message">
                <div className={"header-content-message-introduction"}>
                    <div className="header-content-message-star">
                        {Array.from({length: 5}, (_, index) => (
                            <FaStar key={index} className="message-star-icon"/>
                        ))}
                    </div>
                    <h1> BEM VINDO AO NOSSO ESCRITÓRIO</h1>
                </div>
                <div className={"header-content-message-text"}>
                    <h2> Somos um escritório de advocacia completo com especialização em uma ampla gama
                        de campo jurídicos. Nossa equipe dedicada de advogados presta serviços jurídicos
                        de alta qualidade para indivíduos e empresas.
                    </h2>
                </div>
            </div>
        </nav>
    )
}

export default HeaderContent
'use client'
import {useState, SyntheticEvent} from "react";
import './Accordion.css'
import {AccordionSummary, AccordionDetails} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import Typography from '@mui/material/Typography';
import {FaArrowRight, FaCheckCircle} from "react-icons/fa";
import {GiInjustice} from "react-icons/gi";


function AccordionComponent() {
    const [expanded, setExpanded] = useState<string | false>('');

    const handleChange =
        (panel: string) => (event: SyntheticEvent, newExpanded: boolean) => {
            setExpanded(newExpanded ? panel : false);
        };

    return (
        <div className="accordion-main">
            <div className="accordion-header">
                <p> Dúvidas</p>
            </div>
            <div className="accordion-introduction">
                <div className="accordion-introduction-title">
                    <h2> Dúvidas Frequentes</h2>
                    <GiInjustice className='accordion-introduction-title-icon'/>
                </div>
                <div className="accordion-introduction-text">
                    <p> Em nosso escritório de advocacia, oferecemos assistência jurídica especializada para proteger
                        seus direitos. Nossa equipe de advogados experientes está pronta para representá-lo com um
                        atendimento acolhedor e acessível, explicando tudo de forma clara e simples. Não importa a
                        complexidade do seu problema, estamos aqui para ajudar. Entre em contato e descubra como podemos
                        oferecer a orientação e o suporte que você precisa!</p>
                </div>
            </div>
            <div className="accordion-full">
                <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')} className="accordion">
                    <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" className="accordion-summary"
                                      expandIcon={<FaArrowRight/>}>
                        <Typography className="accordion-summary-content"> Qual é a especialização do escritório de
                            advocacia?</Typography>
                    </AccordionSummary>
                    <AccordionDetails className="accordion-details">
                        <Typography className="accordion-details-content">
                            Nosso escritório é especializado e detém profissionais em direito civil, Imobiliário,
                            trabalhista e previdenciário.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')} className="accordion">
                    <AccordionSummary aria-controls="panel2d-content" id="panel2d-header" className="accordion-summary"
                                      expandIcon={<FaArrowRight/>}>
                        <Typography className="accordion-summary-content"> Qual é o custo estimado dos serviços
                            advocatícios?</Typography>
                    </AccordionSummary>
                    <AccordionDetails className="accordion-details">
                        <Typography className="accordion-details-content">
                            O custo varia conforme a complexidade do caso e será discutido em uma consulta
                            inicial. </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')} className="accordion">
                    <AccordionSummary aria-controls="panel3d-content" id="panel3d-header" className="accordion-summary"
                                      expandIcon={<FaArrowRight/>}>
                        <Typography className="accordion-summary-content">Como agendar uma consulta com um dos
                            advogados?</Typography>
                    </AccordionSummary>
                    <AccordionDetails className="accordion-details">
                        <Typography className="accordion-details-content">
                            As consultas podem ser agendadas por telefone, WhatsApp, formulário de contato do ste ou
                            contato@batistarodrigues.com e-mail, conforme disponibilidade dos profissionais.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')} className="accordion">
                    <AccordionSummary aria-controls="panel4d-content" id="panel4d-header" className="accordion-summary"
                                      expandIcon={<FaArrowRight/>}>
                        <Typography className="accordion-summary-content">Como funciona o processo de acompanhamento do
                            caso?</Typography>
                    </AccordionSummary>
                    <AccordionDetails className="accordion-details">
                        <Typography className="accordion-details-content">
                            O cliente será informado regularmente sobre o andamento do caso por meio de relatórios e
                            reuniões, conforme necessário. Além disso, temos uma área restrita em nosso site para
                            clientes, onde poderão acompanhar o andamento de nossos serviços
                        </Typography>
                    </AccordionDetails>
                </Accordion>
            </div>
        </div>
    );
}

export default AccordionComponent

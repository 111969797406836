'use client'
import { useEffect, useState } from 'react';
import HeaderNavBar from '@/components/headerNavBar/HeaderNavBar';
import HeaderContent from '@/components/headerContent/HeaderContent';
import background01 from "../../../public/background-batistarodrigues-01.webp";
import background02 from "../../../public/background-batistarodrigues-02.webp";
import './Header.css';

function Header() {
    const imagesArray = [
        background01.src,
        background02.src,
    ];
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [translateY, setTranslateY] = useState(0);

    useEffect(() => {
        // Importa o Web Worker usando import.meta.url
        const worker = new Worker(new URL("@/workers/worker.js", import.meta.url));

        const interval = setInterval(() => {
            worker.postMessage({
                type: 'UPDATE_IMAGE_INDEX',
                payload: { currentIndex: currentImageIndex, length: imagesArray.length }
            });
        }, 10000);

        worker.onmessage = (e) => {
            const { type, payload } = e.data;
            if (type === 'UPDATED_IMAGE_INDEX') {
                setCurrentImageIndex(payload);
            }
        };

        return () => {
            clearInterval(interval);
            worker.terminate();
        };
    }, [currentImageIndex, imagesArray.length]);

    useEffect(() => {
        const worker = new Worker(new URL("@/workers/worker.js", import.meta.url));

        const handleScroll = () => {
            worker.postMessage({ type: 'HANDLE_SCROLL', payload: { scrollY: window.scrollY } });
        };

        worker.onmessage = (e) => {
            const { type, payload } = e.data;
            if (type === 'UPDATED_SCROLL') {
                setTranslateY(payload);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
            worker.terminate();
        };
    }, []);

    return (
        <header className="header-main">
            <div className="header-background"
                 style={{ backgroundImage: `url(${imagesArray[currentImageIndex]})`, transform: `translateY(${translateY}px)` }}></div>
            <HeaderNavBar/>
            <HeaderContent/>
        </header>
    );
}

export default Header;

import(/* webpackMode: "eager" */ "/github/workspace/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/github/workspace/node_modules/next/font/google/target.css?{\"path\":\"src/assets/fonts/Antonio.ts\",\"import\":\"Antonio\",\"arguments\":[{\"weight\":[\"300\",\"700\"],\"subsets\":[\"latin\"]}],\"variableName\":\"source\"}");
;
import(/* webpackMode: "eager" */ "/github/workspace/public/Batista & Rodrigues-LOGO.webp");
;
import(/* webpackMode: "eager" */ "/github/workspace/src/app/globals.css");
;
import(/* webpackMode: "eager" */ "/github/workspace/src/components/aboutUs/AboutUs.css");
;
import(/* webpackMode: "eager" */ "/github/workspace/src/components/accordion/Accordion.tsx");
;
import(/* webpackMode: "eager" */ "/github/workspace/src/components/contactForm/ContactForm.tsx");
;
import(/* webpackMode: "eager" */ "/github/workspace/src/components/footer/Footer.css");
;
import(/* webpackMode: "eager" */ "/github/workspace/src/components/contact/Contact.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/github/workspace/src/components/header/Header.tsx");
;
import(/* webpackMode: "eager" */ "/github/workspace/src/components/modelServices/ModelService.css");

import "./HeaderMenuBar.css"
import Image from "next/image";
import LogoBRP from "../../../public/Batista & Rodrigues-LOGO.webp"

interface HeaderMenuBarProps {
    activeMenu: boolean,
    setActiveMenu: (show:boolean)=> void;
}
const HeaderMenuBar: React.FC<HeaderMenuBarProps>= ({activeMenu,setActiveMenu})  => {
    return (
        <div className={"header-menu-bar-main"}>
            <div className={"header-menu-bar-container"}>
                <div className={"header-menu-bar-container-logo"}>
                    <Image
                        src={LogoBRP}
                        alt="Logo Batista & Rodrigues Advogados Associados"
                        width={240}
                        height={45}
                        priority
                    />
                </div>
                <div className={"header-menu-bar-container-list"}>
                    <a onClick={()=> setActiveMenu(false)} href={"#sobre-nos"}>Sobre-nós</a>
                    <a onClick={()=> setActiveMenu(false)} href={"#como-trabalhamos"}>Como trabalhamos</a>
                    <a  onClick={()=> setActiveMenu(false)} href={"#contact"}>Contato</a>
                    <a>Area Restrita</a>
                </div>
            </div>
        </div>
    )
}

export default HeaderMenuBar